.listItem {
  display: flex;
  justify-content: space-between;
  width: 100%;

  background-color: white;
  border-bottom: 1px solid #e8e8e8;

  &:last-child {
    border-bottom-style: none;
  }
}
