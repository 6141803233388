.container {
  width: 100%;

  & button {
    margin-right: 8px;
  }
}

.deleteIcon {
  color: darkred;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-top: 1px solid #eaeaea;
}
