.buttonGroup {
  display: flex;
  justify-content: flex-end;

  & > button {
    margin-top: 20px;
    margin-right: 10px;
  }
}

.prompt {
  margin-bottom: 20px;
}
