.container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 10px;

  & :global(.ant-statistic-title) {
    @media (max-width: 768px) {
      text-overflow: ellipsis;
      max-width: 70px;
    }
  }
}
