.completedTodo {
  text-decoration: line-through;
}

.inPlaceEdit {
  margin-left: 10px !important;
}

.todoListItem {
  display: flex;
}

.todoNoteIcon {
  margin-right: 8px;
}

.todoTitle {
  margin-left: 8px;
  margin-right: 8px;
}
